import * as React from "react";
import { isDev } from "../../../common/helpers/utils";
import { useApi } from "../../../common/hooks/useApi";
import Link from "../../UI-Elements/Navigation/Link";

export type News = {
  title?: string;
  kind?: string;
  domain?: string;
  description?: string;
  url?: string;
  published_at?: string;
  created_at?: string;
  slug?: string;
  source: {
    region: string;
    domain: string;
    path: string;
    title: string;
  };
  currency: {
    code?: string;
    slug?: string;
    title?: string;
    url?: string;
  }[];
  id?: number;
  votes: {
    negative?: number;
    positive?: number;
    important?: number;
    liked?: number;
    disliked?: number;
    lol?: number;
    toxic?: number;
    saved?: number;
    comments?: number;
  };
};
type Props = {
  currency: string;
  pageSize?: number;
};
const baseApi = isDev ? "http://localhost:8888" : "https://einfachcrypto.de";

const CryptoPanicList: React.FC<Props> = ({ currency, pageSize }) => {
  const mapper = (res: any) => res.results;
  const url = `${baseApi}/api/cryptopanic?currencies=${encodeURIComponent(currency)}&pageSize=${pageSize}`;
  const { data, error, loading } = useApi<News[]>(url, mapper);
  console.log(data);

  const links = (data || []).map((item) => ({
    href: item.url,
    title: (
      <div>
        <Link href={item.url ?? ""} external type="hover">
          <h3 className="font-bold">{item.title}</h3>
          <p>{item.description}</p>
        </Link>
        <p>
          <Link href={`https://${item.source.domain}`} external type="text">
            {item?.source?.title ?? item.domain}
          </Link>{" "}
          | {item.created_at} |{" "}
          <Link href={item.url ?? ""} external type="text">
            Discuss on CryptoPanic
          </Link>
        </p>
      </div>
    ),
    external: true,
  }));

  if (error) {
    return <p>An error occured...</p>;
  }
  if (loading) {
    return <p>loading...</p>;
  }

  return <div className="grid gap-2">{links.map((link) => link.title)}</div>;
};
export default CryptoPanicList;
