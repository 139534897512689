import Title from "@components/UI-Elements/Typography/Title";
import React from "react";
import Layout from "../../../components/layout";
import CryptoPanicList from "../../../components/Modules/news/cryptopanic";
import SEO from "../../../components/seo";
import Card from "../../../components/UI-Elements/Card";
import Link from "../../../components/UI-Elements/Navigation/Link";
import CategoryTemplate from "../../../templates/category-page";

const title = "Digitales Gold";
const coins = ["bitcoin"];
const description = "Hier gibt es nur eine. - Bitcoin!";
const Page: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={["digitales gold"]} title={title} robots="noindex" />
      <CategoryTemplate title={title} coins={coins} description={description} />
      <Title>News zu Bitcoin</Title>
      <Card>
        <CryptoPanicList currency="btc" pageSize={10} />
        <p className="text-right">Quelle: <Link href="https://cryptopanic.com" external type="text">cryptopanic.com</Link></p>
      </Card>
    </Layout>
  );
};

export default Page;
